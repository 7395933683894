import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["emailInput", "emailList", "errorMessage", "errorText", "emptyState", "submitBtn"]
    static values = {
        initialEmails: Array
    }

    connect() {
        this.loadInitialEmails()
        this.checkEmptyState()
    }

    loadInitialEmails() {
        if (this.initialEmailsValue && this.initialEmailsValue.length > 0) {
            this.initialEmailsValue.forEach(email => this.addEmailToList(email))
        }
    }

    handleKeyPress(e) {
        if (e.key === "Enter") {
            e.preventDefault()
            this.addEmail()
        }
    }

    addEmail() {
        const email = this.emailInputTarget.value.trim()

        if (!email) {
            this.showError("Please enter an email address")
            return
        }

        if (!this.validateEmail(email)) {
            this.showError("Please enter a valid email address")
            return
        }

        if (this.hasExistingEmail(email)) {
            this.showError("This email is already in the list")
            return
        }

        this.addEmailToList(email)
        this.emailInputTarget.value = ""
        this.hideError()
        this.checkEmptyState()
    }

    addEmailToList(email) {
        const item = document.createElement('div')
        item.className = 'aml-email-item'

        // Create hidden input for the email
        const hiddenInput = document.createElement('input')
        hiddenInput.type = 'hidden'
        hiddenInput.name = 'user[aml_alert_emails][]'
        hiddenInput.value = email

        item.innerHTML = `
      <div class="aml-email-item__content">
        <i class="fa-light fa-envelope aml-icon aml-icon--mail"></i>
        <span class="aml-email-item__address">${email}</span>
      </div>
      <button class="aml-btn aml-btn--icon" type="button" data-action="click->dashboard--emailalerts#removeEmail" data-dashboard--emailalerts-email="${email}">
        <svg class="aml-icon aml-icon--x" width="16" height="16" viewBox="0 0 24 24" fill="none">
          <path d="M18 6L6 18M6 6l12 12" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
        </svg>
      </button>
    `

        item.appendChild(hiddenInput)
        this.emailListTarget.appendChild(item)
    }

    removeEmail(e) {
        const item = e.currentTarget.closest('.aml-email-item')
        item.remove()
        this.checkEmptyState()
    }

    validateEmail(email) {
        return email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
    }

    hasExistingEmail(email) {
        return Array.from(this.emailListTarget.querySelectorAll('.aml-email-item__address'))
            .some(el => el.textContent.trim() === email)
    }

    showError(message) {
        this.errorTextTarget.textContent = message
        this.errorMessageTarget.classList.remove('hidden')
    }

    hideError() {
        this.errorMessageTarget.classList.add('hidden')
    }

    checkEmptyState() {
        const hasEmails = this.emailListTarget.children.length > 0
        this.emptyStateTarget.classList.toggle('hidden', hasEmails)
    }
}