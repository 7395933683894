// Import and register all your controllers from the importmap via controllers/**/*_controller
import { application } from "~/controllers/application"

const controllers = import.meta.glob("./**/*_controller.js", { eager: true });

for (let path in controllers) {
  let module = controllers[path];
  let name = path.match(/\.\/(.+)_controller\.js$/)[1].replaceAll("/", "--");
  application.register(name, module.default);
}
