// app/javascript/controllers/topic_selector_controller.js

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["search", "selectedTopics"]
    static values = {
        checkedTopics: Array,
        topicsMapping: Object
    }

    connect() {
        this.searchInput = this.element.querySelector('.topic-search')
        this.setupEventListeners()
        this.initializeSelectedTopics()
        this.filterTopics('')
    }

    initializeSelectedTopics() {
        // Initialize pre-selected topics
        if (this.checkedTopicsValue) {
            this.checkedTopicsValue.forEach(topic => {
                this.addSelectedTopic(topic)
            })
        }
    }

    setupEventListeners() {
        this.searchInput.addEventListener('input', (e) => this.search(e))
        this.element.querySelectorAll('.topic-checkbox').forEach(checkbox => {
            checkbox.addEventListener('change', (e) => this.toggleTopic(e))
        })
    }

    search(event) {
        const searchTerm = event.target.value.toLowerCase()
        this.filterTopics(searchTerm)
    }

    filterTopics(searchTerm) {
        const topicItems = this.element.querySelectorAll('.topic-item')
        const topicGroups = this.element.querySelectorAll('.topic-group')

        topicGroups.forEach(group => {
            let hasVisibleTopics = false
            const groupTopics = group.querySelectorAll('.topic-item')

            groupTopics.forEach(item => {
                const topicLabel = item.querySelector('.topic-label').textContent.toLowerCase()
                const topicCode = item.querySelector('.topic-checkbox').dataset.topic.toLowerCase()
                const isVisible = topicLabel.includes(searchTerm) || topicCode.includes(searchTerm)
                item.style.display = isVisible ? '' : 'none'
                if (isVisible) hasVisibleTopics = true
            })

            group.style.display = hasVisibleTopics ? '' : 'none'
        })
    }

    toggleTopic(event) {
        const checkbox = event.target
        const topic = checkbox.dataset.topic

        if (checkbox.checked) {
            this.addSelectedTopic(topic)
        } else {
            this.removeSelectedTopic(topic)
        }
    }

    addSelectedTopic(topic) {
        const selectedTopicsContainer = this.element.querySelector('.selected-topics')

        if (this.element.querySelector(`.selected-topic[data-topic="${topic}"]`)) {
            return
        }

        const topicElement = document.createElement('div')
        topicElement.className = 'selected-topic'
        topicElement.dataset.topic = topic

        const label = this.topicsMappingValue[topic] || topic

        topicElement.innerHTML = `
      ${label}
      <span class="remove-topic" role="button" tabindex="0">
        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18 6L6 18M6 6L18 18" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </span>
    `

        topicElement.querySelector('.remove-topic').addEventListener('click', () => {
            this.removeSelectedTopic(topic)
        })

        selectedTopicsContainer.appendChild(topicElement)

        const checkbox = this.element.querySelector(`input[data-topic="${topic}"]`)
        if (checkbox) {
            checkbox.checked = true
        }
    }

    removeSelectedTopic(topic) {
        const selectedTopic = this.element.querySelector(`.selected-topic[data-topic="${topic}"]`)
        if (selectedTopic) {
            selectedTopic.remove()
        }

        const checkbox = this.element.querySelector(`input[data-topic="${topic}"]`)
        if (checkbox) {
            checkbox.checked = false
        }
    }

    submit(event) {
        event.preventDefault()
        const form = event.target

        const selectedTopics = Array.from(this.element.querySelectorAll('.selected-topic'))
            .map(el => el.dataset.topic)

        this.element.querySelectorAll('input[name="excluded_topics[]"]').forEach(el => el.remove())

        selectedTopics.forEach(topic => {
            const hiddenInput = document.createElement('input')
            hiddenInput.type = 'hidden'
            hiddenInput.name = 'excluded_topics[]'
            hiddenInput.value = topic  // This will submit the code, not the label
            form.appendChild(hiddenInput)
        })

        form.submit()
    }
}