// app/javascript/controllers/dashboard/analyzer_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        console.log("Analyzer controller connected!")
    }

    toggleIndicator(event) {
        console.log("toggleIndicator called!")
        event.preventDefault()
        const indicator = event.currentTarget.closest('.document-app__indicator')
        const content = indicator.querySelector('.document-app__indicator-content')
        console.log('indicator:', indicator)
        console.log('content:', content)

        content.style.display = content.style.display === 'none' ? 'block' : 'none'
    }

    switchTab(event) {
        console.log("switchTab called!")
        event.preventDefault()
    }

    copyToClipboard(event) {
        console.log("copyToClipboard called!")
        event.preventDefault()
    }

    handleDragOver(event) {
        console.log("handleDragOver called!")
        event.preventDefault()
    }

    handleDragEnter(event) {
        console.log("handleDragEnter called!")
        event.preventDefault()
    }

    handleDragLeave(event) {
        console.log("handleDragLeave called!")
        event.preventDefault()
    }

    handleDrop(event) {
        console.log("handleDrop called!")
        event.preventDefault()
    }

    showInfo(event) {
        console.log("showInfo called!")
        event.preventDefault()
    }

    closePanel(event) {
        console.log("closePanel called!")
        event.preventDefault()
    }
}