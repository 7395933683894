import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    window.signInWithPasskey = async function() {
        try {
            // Show loading state
            const button = document.querySelector('[data-passkey-signin]');
            const originalText = button.innerHTML;
            button.disabled = true;
            button.innerHTML = '<i class="fa-kit fa-fido-passkey-mark fa-fade me-2 bx bx-smile label-icon" id="passkeyIcon" style="font-size: 26px;" aria-hidden="true"></i><span id="passkeyText">Signing in...</span>';

            // Get the authentication options from the server
            const optionsResponse = await fetch('/users/sign_in_with_passkey', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json'
                }
            });

            const options = await optionsResponse.json();

            // Convert base64url encoded challenge to ArrayBuffer
            const publicKeyCredentialRequestOptions = {
                challenge: base64URLToBuffer(options.challenge),
                timeout: options.timeout || 60000,
                rpId: window.location.hostname,
                allowCredentials: options.allowCredentials?.map(credential => ({
                    ...credential,
                    id: base64URLToBuffer(credential.id),
                })) || [],
                userVerification: options.userVerification || 'preferred',
            };

            // Request the credential from the authenticator
            const credential = await navigator.credentials.get({
                publicKey: publicKeyCredentialRequestOptions
            });

            // Convert the credential for sending to the server
            const authenticatorResponse = {
                id: credential.id,
                rawId: Array.from(new Uint8Array(credential.rawId)),
                response: {
                    authenticatorData: Array.from(new Uint8Array(credential.response.authenticatorData)),
                    clientDataJSON: Array.from(new Uint8Array(credential.response.clientDataJSON)),
                    signature: Array.from(new Uint8Array(credential.response.signature))
                },
                type: credential.type,
                clientExtensionResults: credential.getClientExtensionResults()
            };

            // Get CSRF token
            const csrfToken = document.querySelector('meta[name="csrf-token"]')?.content;

            // Send the credential to the server for verification
            const verificationResponse = await fetch('/users/authenticate_with_passkey', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'X-CSRF-Token': csrfToken
                },
                body: JSON.stringify(authenticatorResponse)
            });

            const result = await verificationResponse.json();

            if (result.status === 'success') {
                // Show success message before redirect
                button.innerHTML = '<i class="fas fa-check me-2"></i>Success!';

                // Redirect to the specified URL or reload the page
                setTimeout(() => {
                    if (result.redirect_url) {
                        window.location.href = result.redirect_url;
                    } else {
                        window.location.reload();
                    }
                }, 500);
            } else {
                throw new Error(result.message || 'Authentication failed');
            }

        } catch (error) {
            console.error('Passkey authentication error:', error);
            const errorMessage = error.message || 'Failed to authenticate with passkey';

            // Show error in the UI
            if (typeof Swal !== 'undefined') {
                Swal.fire({
                    title: 'Authentication Error',
                    text: errorMessage,
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            } else {
                alert(errorMessage);
            }

            // Reset button state
            const button = document.querySelector('[data-passkey-signin]');
            if (button) {
                button.disabled = false;
                button.innerHTML = '<i class="fa-kit fa-fido-passkey-mark bx bx-smile label-icon" id="passkeyIcon" style="font-size: 26px;" aria-hidden="true"></i><span id="passkeyText">Sign in with a Passkey</span>';
            }
        }
    }

    // Helper function to convert Base64URL to ArrayBuffer
    function base64URLToBuffer(base64URL) {
        const base64 = base64URL.replace(/-/g, '+').replace(/_/g, '/');
        const padLen = (4 - (base64.length % 4)) % 4;
        const padded = base64 + '='.repeat(padLen);
        const binary = atob(padded);
        const buffer = new Uint8Array(binary.length);

        for (let i = 0; i < binary.length; i++) {
            buffer[i] = binary.charCodeAt(i);
        }
        return buffer.buffer;
    }
  }
}
