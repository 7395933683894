// app/javascript/controllers/dashboard/scansettings_controller.js

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "frequencyGroup",
        "frequencyInput",
        "sensitivitySlider",
        "sensitivityInput",
        "sensitivityValue",
        "sensitivityLabel"
    ]

    static values = {
        initialDays: Number,
        initialSensitivity: Number
    }

    connect() {
        this.initializeFrequency()
        this.initializeSensitivity()
        this.updateSensitivityLabel()
    }

    initializeFrequency() {
        const days = this.initialDaysValue
        const option = this.frequencyGroupTarget.querySelector(`[data-days="${days}"]`)
        if (option) {
            option.classList.add('aml-radio-option--selected')
            this.frequencyInputTarget.value = days
        }
    }

    initializeSensitivity() {
        const percentValue = Math.round(this.initialSensitivityValue * 100)
        this.sensitivitySliderTarget.value = percentValue
        this.sensitivityValueTarget.textContent = `${percentValue}% Sensitivity`
        this.sensitivityInputTarget.value = this.initialSensitivityValue
    }

    selectFrequency(event) {
        const option = event.currentTarget
        const days = option.dataset.days

        // Update visual state
        this.frequencyGroupTarget.querySelectorAll('.aml-radio-option').forEach(el => {
            el.classList.remove('aml-radio-option--selected')
        })
        option.classList.add('aml-radio-option--selected')

        // Update hidden input
        this.frequencyInputTarget.value = days
    }

    updateSensitivity(event) {
        const percentValue = event.target.value
        const decimalValue = (percentValue / 100).toFixed(2)

        this.sensitivityValueTarget.textContent = `${percentValue}% Sensitivity`
        this.sensitivityInputTarget.value = decimalValue
        this.updateSensitivityLabel()
    }

    updateSensitivityLabel() {
        const value = parseInt(this.sensitivitySliderTarget.value)
        let label = ''

        if (value < 60) {
            label = 'Low - Very wide matching, high false positive rate'
        } else if (value < 80) {
            label = 'Medium - More potential matches, higher false positive rate'
        } else if (value < 100) {
            label = 'High - Balance between matches and accuracy'
        } else {
            label = 'Maximum - Highest accuracy, name, nationality and birth date must match'
        }

        this.sensitivityLabelTarget.textContent = label
    }
}