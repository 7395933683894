// app/javascript/controllers/dashboard/webhooks_controller.js

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "urlInput",
        "webhookList",
        "errorMessage",
        "errorText",
        "emptyState",
        "addButton",
        "environmentSelect"
    ]

    static values = {
        addUrl: String
    }

    connect() {
        this.updateEmptyState()
    }

    handleKeyPress(event) {
        if (event.key === "Enter") {
            event.preventDefault()
            this.addWebhook(event)
        }
    }

    async addWebhook(event) {
        event.preventDefault()
        const url = this.urlInputTarget.value.trim()
        const environment = this.environmentSelectTarget.value

        if (!environment) {
            this.showError("Please select an environment")
            return
        }

        if (!url) {
            this.showError("Please enter a webhook URL")
            return
        }

        if (!this.validateUrl(url)) {
            this.showError("Please enter a valid URL")
            return
        }

        if (this.hasExistingWebhook(url)) {
            this.showError("This webhook URL already exists")
            return
        }

        // Add temporary webhook with loading state
        const tempWebhook = this.createLoadingWebhook(url, environment)
        this.webhookListTarget.insertAdjacentHTML('beforeend', tempWebhook)

        // Disable input and button during request
        this.addButtonTarget.disabled = true
        this.urlInputTarget.disabled = true
        this.environmentSelectTarget.disabled = true

        try {
            const response = await fetch(this.addUrlValue, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
                },
                body: JSON.stringify({ url, environment })
            })

            if (!response.ok) throw new Error('Network response was not ok')

            const data = await response.json()

            // Replace loading webhook with actual data
            const webhookElement = this.webhookListTarget.querySelector(`[data-url="${url}"]`)
            if (webhookElement) {
                webhookElement.outerHTML = this.createWebhookHtml(data)
            }

            this.urlInputTarget.value = ""
            this.environmentSelectTarget.value = "" // Reset environment select
            this.hideError()
        } catch (error) {
            this.showError("We were unable to add the webhook. Please try again and ensure that the URL is using HTTPS with a valid SSL certificate.")
            // Remove temporary webhook
            const webhookElement = this.webhookListTarget.querySelector(`[data-url="${url}"]`)
            if (webhookElement) {
                webhookElement.remove()
            }
        } finally {
            this.addButtonTarget.disabled = false
            this.urlInputTarget.disabled = false
            this.environmentSelectTarget.disabled = false
            this.updateEmptyState()
        }
    }

    async removeWebhook(event) {
        const button = event.currentTarget
        const webhookEl = button.closest('.aml-webhook')
        const webhookId = button.dataset.id

        if (!confirm('Are you sure you want to remove this webhook?')) return

        try {
            const response = await fetch(this.addUrlValue, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
                },
                body: JSON.stringify({ id: webhookId })
            })

            if (!response.ok) throw new Error('Network response was not ok')

            webhookEl.remove()
            this.updateEmptyState()
        } catch (error) {
            this.showError("Failed to remove webhook. Please try again.")
        }
    }

    createLoadingWebhook(url) {
        return `
      <div class="aml-webhook" data-url="${url}">
        <div class="aml-webhook__header">
          <div class="aml-webhook__status">
            <div class="aml-webhook__status-dot aml-webhook__status-dot--pending"></div>
            <div class="aml-webhook__url">${url}</div>
          </div>
          <div class="aml-webhook__actions">
            <button type="button" class="aml-btn aml-btn--ghost" disabled>Remove</button>
          </div>
        </div>
        <div class="aml-webhook__metrics">
          ${this.createLoadingMetric('Response Time', '-')}
          ${this.createLoadingMetric('Activity', '-', 'Loading...')}
          ${this.createLoadingMetric('Error Rate', '-', 'Loading...')}
        </div>
      </div>
    `
    }

    createLoadingMetric(title, value, details = '') {
        return `
      <div class="aml-webhook__metric">
        <div class="aml-webhook__metric-header">
          <svg class="aml-icon" width="16" height="16" viewBox="0 0 24 24" fill="none">
            <path d="M12 6v6l4 2" stroke="currentColor" stroke-width="2"/>
          </svg>
          ${title}
        </div>
        <div class="aml-webhook__metric-value">${value}</div>
        ${details ? `<div class="aml-webhook__metric-details">${details}</div>` : ''}
      </div>
    `
    }

    createWebhookHtml(webhook) {
        const errorRateClass = this.getErrorRateClass(webhook.error_rate_percentage)

        return `
      <div class="aml-webhook" data-url="${webhook.url}">
        <div class="aml-webhook__header">
          <div class="aml-webhook__status">
            <div class="aml-webhook__status-dot aml-webhook__status-dot--${webhook.http_code === 200 ? 'active' : webhook.http_code === 0 ? 'pending' : 'failed'}"></div>
            <div class="aml-webhook__url">${webhook.url}</div>
          </div>
          <div class="aml-webhook__actions">
            <button type="button" class="aml-btn aml-btn--ghost" data-id="${webhook.id}" data-action="dashboard--webhooks#removeWebhook">
              Remove
            </button>
          </div>
        </div>
        <div class="aml-webhook__metrics">
          <div class="aml-webhook__metric">
            <div class="aml-webhook__metric-header">
              <i class="fa-regular fa-clock aml-icon aml-icon--clock"></i>
              Response Time
            </div>
            <div class="aml-webhook__metric-value">
              ${webhook.response_time_ms}<span class="aml-webhook__metric-unit">ms</span>
            </div>
          </div>

          <div class="aml-webhook__metric">
            <div class="aml-webhook__metric-header">
              <svg class="aml-icon aml-icon--activity" width="16" height="16" viewBox="0 0 24 24" fill="none">
                <path d="M22 12h-4l-3 9L9 3l-3 9H2" stroke="currentColor" stroke-width="2"/>
              </svg>
              Activity
            </div>
            <div class="aml-webhook__metric-value">
              ${webhook.activity_24_hours.requests.toLocaleString()}
            </div>
            <div class="aml-webhook__metric-details">
              ${webhook.activity_24_hours.successful.toLocaleString()} successful • ${webhook.activity_24_hours.failed.toLocaleString()} failed
            </div>
          </div>

          <div class="aml-webhook__metric">
            <div class="aml-webhook__metric-header">
              <i class="fa-regular fa-circle-exclamation aml-icon aml-icon--alert-triangle"></i>
              Error Rate
            </div>
            <div class="aml-webhook__metric-value ${errorRateClass}">
              ${webhook.error_rate_percentage}%
            </div>
            <div class="aml-webhook__metric-details">Last 24 hours</div>
          </div>
        </div>
      </div>
    `
    }

    getErrorRateClass(rate) {
        if (rate > 10) return 'aml-webhook__metric-value--danger'
        if (rate > 5) return 'aml-webhook__metric-value--warning'
        return 'aml-webhook__metric-value--success'
    }

    validateUrl(url) {
        try {
            new URL(url)
            return true
        } catch {
            return false
        }
    }

    hasExistingWebhook(url) {
        return Array.from(this.webhookListTarget.querySelectorAll('.aml-webhook__url'))
            .some(el => el.textContent === url)
    }

    showError(message) {
        this.errorTextTarget.textContent = message
        this.errorMessageTarget.classList.remove('hidden')
    }

    hideError() {
        this.errorMessageTarget.classList.add('hidden')
    }

    updateEmptyState() {
        const hasWebhooks = this.webhookListTarget.children.length > 0
        this.emptyStateTarget.classList.toggle('hidden', hasWebhooks)
    }
}