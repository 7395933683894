import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {

      this.element.classList.remove('flashing-row');
      void this.element.offsetWidth;
      this.element.classList.add('flashing-row');
      setTimeout(() => {
          this.element.classList.remove('flashing-row');
      }, 2000);

  }
}
