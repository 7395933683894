import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // app/javascript/passkey_cleanup.js
    async function removePasskeyFromDevice(credentialId) {
        try {
            if (!window.PublicKeyCredential || !window.PublicKeyCredential.isConditionalMediationAvailable) {
                throw new Error('WebAuthn is not supported in this browser');
            }

            // Convert the credential ID from base64url to ArrayBuffer
            const credentialIdBuffer = base64URLToBuffer(credentialId);

            // Try to remove the credential from the authenticator
            await navigator.credentials.create({
                publicKey: {
                    challenge: new Uint8Array(32), // Random challenge
                    rp: {
                        name: 'Your App Name',
                        id: window.location.hostname
                    },
                    user: {
                        id: new Uint8Array(32), // Random user ID
                        name: 'cleanup@example.com',
                        displayName: 'Cleanup Operation'
                    },
                    pubKeyCredParams: [{
                        type: 'public-key',
                        alg: -7
                    }],
                    excludeCredentials: [{
                        type: 'public-key',
                        id: credentialIdBuffer
                    }],
                    authenticatorSelection: {
                        authenticatorAttachment: 'platform',
                        requireResidentKey: true
                    }
                }
            });

            return true;
        } catch (error) {
            console.log('Error removing passkey from device:', error);
            return false;
        }
    }

    async function deletePasskey(passkeyId, credentialId) {
        try {
            const button = document.querySelector(`[data-delete-passkey-id="${passkeyId}"]`);
            if (button) {
                button.disabled = true;
                button.innerHTML = '<i class="fas fa-spinner fa-spin"></i>';
            }

            // First try to remove from device
            await removePasskeyFromDevice(credentialId);

            // Then remove from server
            const response = await fetch(`/passkeys/${passkeyId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
                }
            });

            if (!response.ok) {
                throw new Error('Failed to remove passkey from server');
            }

            // Remove the passkey element from the UI
            const passkeyElement = document.querySelector(`#passkey-${passkeyId}`);
            if (passkeyElement) {
                passkeyElement.remove();
            }

            // Show success message
            if (typeof Swal !== 'undefined') {
                await Swal.fire({
                    title: 'Success',
                    text: 'Passkey was removed successfully',
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false
                });
            }

        } catch (error) {
            console.error('Error:', error);
            if (typeof Swal !== 'undefined') {
                await Swal.fire({
                    title: 'Error',
                    text: error.message || 'Failed to remove passkey',
                    icon: 'error'
                });
            }
        } finally {
            // Reset button state if it exists
            const button = document.querySelector(`[data-delete-passkey-id="${passkeyId}"]`);
            if (button) {
                button.disabled = false;
                button.innerHTML = '<i class="fas fa-trash"></i> Remove';
            }
        }
    }

    // Helper function to convert base64url to ArrayBuffer
    function base64URLToBuffer(base64URL) {
        const base64 = base64URL.replace(/-/g, '+').replace(/_/g, '/');
        const padLen = (4 - (base64.length % 4)) % 4;
        const padded = base64 + '='.repeat(padLen);
        const binary = atob(padded);
        const buffer = new Uint8Array(binary.length);

        for (let i = 0; i < binary.length; i++) {
            buffer[i] = binary.charCodeAt(i);
        }
        return buffer.buffer;
    }

    // Make function globally available
    window.deletePasskey = deletePasskey;
  }
}
