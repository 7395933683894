import { Controller } from "@hotwired/stimulus"
import * as echarts from 'echarts';

export default class extends Controller {
  connect() {
    var chartDom = document.getElementById('check-db-chart');
    var myChart = echarts.init(chartDom);
    var option;

    option = {
        series: [{
            type: 'pie',
            radius: ['70%', '90%'],
            avoidLabelOverlap: false,
            startAngle: 0,
            label: {
                show: false,
                position: 'center'
            },
            emphasis: {
                label: {
                    show: false,
                    fontSize: '30',
                    fontWeight: 'bold'
                }
            },
            labelLine: {
                show: false
            },
            data: [
                {value: 85, name: 'Approved', itemStyle: {color: '#8ce99a'}},
                {value: 5, name: 'Rejected', itemStyle: {color: '#ff6b6b'}},
                {value: 5, name: 'Request action', itemStyle: {color: '#bd77dd'}},
                {value: 5, name: 'In progress', itemStyle: {color: '#6bbcff'}},


            ]
        }],
        graphic: [{
            type: 'text',
            left: 'center',
            top: '40%',
            style: {
                text: '9540',
                fontSize: 30
            }
        }, {
            type: 'text',
            left: 'center',
            top: '58%',
            style: {
                text: 'Total applicants',
                fontSize: 11,
                color: '#c3c3c3'
            }
        }]
    };

    option && myChart.setOption(option);
  }
}
