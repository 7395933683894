// app/javascript/controllers/dashboard/webhook_test_controller.js

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "eventType",
        "preview",
        "sendButton",
        "buttonIcon",
        "buttonText",
        "result",
        "responseTime",
        "statusCode"
    ]

    connect() {



    }


    async sendTest(event) {
        const button = event.currentTarget
        this.startLoading()
        this.hideResult()
        const webhookUrl = button.dataset.webhookUrl

        try {
            const response = await fetch(webhookUrl, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
                },
                body: JSON.stringify({
                    event_type: this.eventTypeTarget.value
                })
            })

            const data = await response.json()

            if (!response.ok) throw new Error(data.error || 'Test failed')

            this.showResult(data)
        } catch (error) {
            console.error('Webhook test failed:', error)
            // Could show an error message here
        } finally {
            this.stopLoading()
        }
    }

    startLoading() {
        this.sendButtonTarget.disabled = true
        this.buttonIconTarget.classList.add('aml-test__button-icon--spinning')
        this.buttonTextTarget.textContent = 'Sending...'
    }

    stopLoading() {
        this.sendButtonTarget.disabled = false
        this.buttonIconTarget.classList.remove('aml-test__button-icon--spinning')
        this.buttonTextTarget.textContent = 'Send Test Event'
    }

    showResult(data) {
        this.resultTarget.classList.remove('hidden')
        this.responseTimeTarget.textContent = `${data.response_time_ms}ms`
        this.statusCodeTarget.textContent = `HTTP ${data.response_code}`
    }

    hideResult() {
        this.resultTarget.classList.add('hidden')
    }
}