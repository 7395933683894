// app/javascript/controllers/turbo_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    loadFrame(event) {
        event.preventDefault()
        const frame = document.querySelector(`#${event.params.frame}`)
        const url = event.params.url
        frame.setAttribute("src", url)
    }
}