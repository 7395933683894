import { Controller } from "@hotwired/stimulus"
import intlTelInput from 'intl-tel-input';

export default class extends Controller {
  connect() {
    const controllerParams = JSON.parse(this.data.get("params"));

    document.addEventListener('turbo:load', function () {
        const updateBillingModal = document.getElementById('updateBillingModal');
        const submitButton = document.getElementById('submit-payment');
        const paymentMessage = document.getElementById('payment-message');
        let stripe, elements, paymentElement, clientSecret;

        // Initialize Stripe
        stripe = Stripe(controllerParams["stripePublicKey"]);

        updateBillingModal.addEventListener('show.bs.modal', function () {
            // Fetch the client secret when the modal starts to show
            const params = {
                do_what: 'get_payment_method_secret',
            };

            fetch(controllerParams['dashboardBillingContactPath'], {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                },
                body: JSON.stringify(params)
            })
                .then(response => response.json())
                .then(data => {
                    clientSecret = data.client_secret;
                    console.log('Client secret:', clientSecret);
                })
                .catch(error => {
                    console.error('Error fetching client secret:', error);
                });
        });

        updateBillingModal.addEventListener('shown.bs.modal', function () {
            // Create and mount the payment element after the modal is fully shown
            if (clientSecret) {
                const appearance = { /* appearance */};
                elements = stripe.elements(clientSecret, {appearance});
                paymentElement = elements.create('payment');

                const paymentElementContainer = document.getElementById('payment-element');
                if (paymentElementContainer) {
                    paymentElement.mount('#payment-element');
                } else {
                    console.error('Payment element container not found');
                }
            } else {
                console.error('Client secret not available');
            }
        });

        submitButton.addEventListener('click', async function (e) {
            e.preventDefault();
            setLoading(true);

            try {
                const {error} = await stripe.confirmSetup({
                    elements,
                    confirmParams: {
                        return_url: controllerParams["dashboardBillingContactIndexUrl"],
                    },
                });

                if (error) {
                    showMessage(error.message);
                } else {
                    showMessage("Payment successful!");
                    // You might want to reload the page or update the UI here
                }
            } catch (error) {
                showMessage("An unexpected error occurred.");
                console.error('Error during payment confirmation:', error);
            } finally {
                setLoading(false);
            }
        });

        function setLoading(isLoading) {
            submitButton.disabled = isLoading;
            submitButton.textContent = isLoading ? "Processing..." : "Update payment method";
        }

        function showMessage(messageText) {
            paymentMessage.textContent = messageText;
            paymentMessage.classList.remove('hidden');
            setTimeout(function () {
                paymentMessage.classList.add('hidden');
                paymentMessage.textContent = "";
            }, 4000);
        }
    });

    $(document).ready(function () {
        var input = document.querySelector("#organization_billing_paymethod_mobile_phone");
        var iti = intlTelInput(input, {
            initialCountry: controllerParams["defaultCountry"],
            strictMode: true,
            nationalMode: true,
            hiddenInput: () => ({ phone: "full_phone", country: "country_code" }),

            loadUtilsOnInit: "https://cdn.jsdelivr.net/npm/intl-tel-input@24.6.0/build/js/utils.js",
        });


        $('#organization_billing_paymethod_address_country_id').on('change', function () {

            var selectedCountryId = $(this).val();

            // Make an AJAX request to fetch data from the API
            $.ajax({
                url: controllerParams['dashboardBillingContactIndexPath'] +'&country_id=' + selectedCountryId,  // Adjust the URL to your API endpoint
                method: 'GET',
                success: function (response) {
                    // Assuming 'response' contains the data you want to show in the span
                    $('#regnumber').text(response.company_id);
                    $('#taxnumber').text(response.tax_id_company);
                    $('#eu_taxnumber').text(response.tax_id_company);


                    if (response.sanctions){
                        $(".sanction-alert").show();
                    }else{
                        $(".sanction-alert").hide();
                    }

                    if (response.eu_vat) {
                        $("#eu-vat").show();
                        $("#other-vat").hide();

                        countryCode = response.country_code
                        if(countryCode == "GR"){
                            //Spacial handling for Greece
                            countryCode = "EL"
                        }
                        placeholderText = countryCode + "00000000000000"
                        $("#organization_billing_paymethod_company_eu_vat_number").attr('placeholder', placeholderText);



                    } else {
                        $("#eu-vat").hide();
                        $("#other-vat").show();
                    }



                },
                error: function (xhr, status, error) {
                    // Handle any error
                    $('#countryInfo').text('Failed to fetch country info.');
                }
            });
        });

    });

    // Set default country to visitor country
    $(document).ready(function () {
        $('#organization_billing_paymethod_address_country_id').val(controllerParams['defaultCountryId']).trigger('change');
    });
  }
}
