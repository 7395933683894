// app/javascript/controllers/dashboard/webhook_history_controller.js

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["details", "toggleIcon"]

    toggleEvent(event) {
        const webhookId = event.currentTarget.closest('[data-webhook-id]').dataset.webhookId
        const detailsEl = event.currentTarget.nextElementSibling
        const toggleIcon = event.currentTarget.querySelector('[data-webhook-history-target="toggleIcon"]')

        detailsEl.classList.toggle('hidden')
        toggleIcon.classList.toggle('webhook-history__toggle-icon--expanded')
    }

    async resendWebhook(event) {
        const button = event.currentTarget
        const webhookId = button.dataset.webhookId
        const webhookUrl = button.dataset.webhookUrl

        // Prevent double-clicking
        button.disabled = true

        // Add spinning animation to icon
        const icon = button.querySelector('svg')
        icon.classList.add('webhook-history__action-icon--spinning')

        try {
            const response = await fetch(webhookUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
                }
            })

            if (!response.ok) throw new Error('Failed to resend webhook')

            // Optionally refresh the entire webhook history or just update the status
            const data = await response.json()

            // Find the status badge and update it
            const statusBadge = button.closest('.webhook-history__event')
                .querySelector('.webhook-history__status-badge')

            // Update status badge class and text
            statusBadge.className = `webhook-history__status-badge webhook-history__status-badge--${data.status}`
            statusBadge.textContent = data.status

            // Update metrics if they exist
            const metricsContainer = button.closest('.webhook-history__status-group')
            const metrics = metricsContainer.querySelectorAll('.webhook-history__metric')
            if (metrics.length > 0) {
                metrics[0].textContent = `${data.response_time_ms}ms`
                metrics[1].textContent = `HTTP ${data.response_code}`
            }

            // Update status icon
            const statusIcon = button.closest('.webhook-history__event')
                .querySelector('.webhook-status-icon')
            if (statusIcon) {
                statusIcon.className = `webhook-status-icon webhook-status-icon--${data.status}`
            }

            // If details are expanded, update the response details too
            const detailsEl = button.closest('.webhook-history__event')
                .querySelector('.webhook-history__event-details')
            if (detailsEl && !detailsEl.classList.contains('hidden')) {
                const responseTimeEl = detailsEl.querySelector('.webhook-history__detail-value[data-response-time]')
                const responseCodeEl = detailsEl.querySelector('.webhook-history__detail-value[data-response-code]')

                if (responseTimeEl) {
                    responseTimeEl.textContent = `${data.response_time_ms}ms`
                }
                if (responseCodeEl) {
                    responseCodeEl.textContent = `HTTP ${data.response_code}`
                }
            }

        } catch (error) {
            console.error('Failed to resend webhook:', error)
            const eventEl = button.closest('.webhook-history__event')

            // Add temporary error message
            const errorEl = document.createElement('div')
            errorEl.className = 'webhook-history__error-message'
            errorEl.textContent = 'Failed to resend webhook'
            eventEl.appendChild(errorEl)

            // Remove error message after 3 seconds
            setTimeout(() => {
                errorEl.remove()
            }, 3000)
        } finally {
            // Re-enable button and remove spinning animation
            button.disabled = false
            icon.classList.remove('webhook-history__action-icon--spinning')
        }
    }

    // Helper to format the response code class
    getResponseCodeClass(code) {
        if (code >= 200 && code < 300) return 'webhook-history__metric--success'
        if (code >= 400 && code < 500) return 'webhook-history__metric--warning'
        if (code >= 500) return 'webhook-history__metric--error'
        return ''
    }

    // Helper to update the timestamp to now
    updateTimestamp(element) {
        const now = new Date()
        const formatter = new Intl.DateTimeFormat('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        })
        element.textContent = formatter.format(now)
    }
}